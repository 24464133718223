* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
::-webkit-scrollbar {
    width: 2px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: #ffd950;
}
body {
    font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Poppins", sans-serif;
}